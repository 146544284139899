/* eslint-disable react/prop-types */
import * as React from 'react';
import { Typography } from '../DesignSystemV2/Typography';
import { withMarketingSection } from '../withMarketingSection';
import styled from 'styled-components';
import { colorsV2 } from '../../style/colors-v2';
import { useBookReviews } from '../../shared/LandingBookSelling/components/TabUserReviews';
import { drop, take } from 'lodash';
import { getWindow } from 'ssr-window';
import ReactPaginate from 'react-paginate';
import IconChevronUp from '../../shared/SchedulePage/icons/IconChevronUp';
import BookUserReviewCard from './BookUserReviewCard';
import { BuyNowButton } from './Buttons';
import { deviceBreakpoints } from '../../utils/hooks/useDeviceQuery.hook';

const IconChevronLeft = styled(IconChevronUp)`
  transform: rotate(-90deg);
`;
const IconChevronRight = styled(IconChevronUp)`
  transform: rotate(90deg);
`;
const Description = styled.div`
  @media ${deviceBreakpoints.lg} {
    max-width: 712px;
  }
`;
const BookReviewSectionContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  .youtube-inline-video {
    border-radius: 8px;
  }
  ${Description} {
    margin-top: 32px;
  }
`;
const BookReviewsWrapper = styled.div`
  display: grid;
  gap: 16px;
  .pagination {
    padding-inline-start: 0;
    justify-content: center;
    .page {
      a {
        background-color: ${colorsV2.black60};
        color: ${colorsV2.white100};
      }
    }
    .previous,
    .next {
      svg {
        color: ${colorsV2.white100};
      }
    }
  }

  @media ${deviceBreakpoints.md} {
    .pagination {
      justify-content: end;
    }
  }
`;
const BuyNowButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;
const BookReviews = ({ pageLimit = 4, mobilePageLimit = 2 }) => {
  const data = useBookReviews();
  const [page, setPage] = React.useState(0);
  const pageCount = Math.ceil(data.length / pageLimit);
  const pagingData = take(drop(data, page * pageLimit), pageLimit);

  const window = getWindow();

  return (
    <BookReviewsWrapper>
      {pagingData.map((item, index) => {
        return (
          <React.Fragment key={item.id + index}>
            <BookUserReviewCard data={item} />
            {index < pagingData.length - 1 && (
              <div className="horizontal-separator" />
            )}
          </React.Fragment>
        );
      })}
      {pageCount > 1 && (
        <ReactPaginate
          previousLabel={
            <button>
              <IconChevronLeft />
            </button>
          }
          nextLabel={
            <button>
              <IconChevronRight />
            </button>
          }
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={p => {
            setPage(p.selected);
            const elm = document.getElementsByClassName('tab-header');
            if (elm && elm[0]) {
              window.scrollTo({
                top:
                  window.pageYOffset + elm[0].getBoundingClientRect().top - 120,
                behavior: 'smooth'
              });
            }
          }}
          containerClassName={'pagination'}
          activeClassName={'active'}
          pageClassName="page"
        />
      )}
    </BookReviewsWrapper>
  );
};
const BookReview = () => (
  <BookReviewSectionContainer>
    <Typography
      variant="bold/32-40"
      tabletVariant="bold/40-48"
      className="tab-header"
      style={{ textAlign: 'center', color: colorsV2.white100 }}
    >
      Review và đánh giá
    </Typography>
    <Description>
      <BookReviews />
      <BuyNowButtonWrapper>
        <BuyNowButton />
      </BuyNowButtonWrapper>
    </Description>
  </BookReviewSectionContainer>
);

export default withMarketingSection(BookReview, {
  selfish: true,
  style: { paddingTop: 80, backgroundColor: colorsV2.black80 },
  id: 'danh-gia'
});
