import * as React from 'react';

/* eslint-disable react/prop-types */
import { HeaderSchedulePage } from '../../shared/Header/HeaderSchedulePage';
import { BuyNowHeaderButton } from './Buttons';
import { Link } from 'gatsby';

const MENU_DATA = [
  {
    link: '#gioi-thieu',
    text: 'Giới thiệu',
    subMenu: []
  },
  {
    link: '#noi-bat',
    text: 'Nổi bật',
    subMenu: []
  },
  {
    link: '#danh-gia',
    text: 'Đánh giá',
    subMenu: []
  },
  {
    link: '#chi-tiet',
    text: 'Chi tiết',
    subMenu: []
  },
  {
    link: '#qna',
    text: 'Q&As',
    subMenu: []
  }
];

const HeaderLandingDolBook = ({ ...rest }) => (
  <HeaderSchedulePage
    menuData={MENU_DATA}
    hideMobileBottomNav
    hideMobileSideBar
    noRedirectHome
    CTAButton={
      <div className="cta-actions">
        <BuyNowHeaderButton />
      </div>
    }
    {...rest}
  />
);

export default HeaderLandingDolBook;
