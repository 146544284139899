/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

import { Section, withMarketingSection } from 'components/withMarketingSection';

import { deviceBreakpoints } from '../../utils/hooks/useDeviceQuery.hook';
import { colorsV2 } from '../../style/colors-v2';

const LinkList = styled.div`
  a {
    padding: 0 8px;
    color: ${colorsV2.gray100};
    :hover {
      color: ${colorsV2.primary100};
      border-bottom: 1.5px solid ${colorsV2.primary100};
    }
  }
  a:first-child {
    padding-left: 0;
  }
  a:not(:last-child) {
    border-right: 1px solid ${colorsV2.gray100};
  }
`;

const Main = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 32px;
  border-top: 1px solid ${colorsV2.gray100};

  .text-copyright {
    color: #8f92a1;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .multi-nested-column {
    .column {
      min-width: 84px;
      margin-right: 68px;
    }
  }

  .column-left {
    min-width: 200px;
  }
  .column-middle {
    min-width: 140px;
  }
  .column-right {
    min-width: 200px;
  }
  .contact-map {
    border-radius: 16px;
  }

  @media ${deviceBreakpoints.mobile} {
    flex-direction: column;
  }

  @media ${deviceBreakpoints.mobile}, ${deviceBreakpoints.tablet} {
    justify-content: center;
    .col0 {
      text-align: center;
    }
    ${LinkList} {
      margin-top: 12px;
      text-align: center;
    }
  }
`;

const Footer = () => {
  return (
    <Main>
      <div className="col0">
        <span className="text-copyright">
          © {new Date().getFullYear()} DOL English. All rights reserved.
        </span>
      </div>
      <LinkList>
        <a href="/lien-he" rel="nofollow" target="_blank">
          Liên hệ
        </a>
        <a href="/about-us" rel="nofollow" target="_blank">
          Giới thiệu
        </a>
        <a href="/chinh-sach-bao-mat" rel="nofollow" target="_blank">
          Chính sách Bảo mật
        </a>
        <a href="/dieu-khoan-su-dung" rel="nofollow" target="_blank">
          Điều khoản Sử dụng
        </a>
      </LinkList>
    </Main>
  );
};

const MainFooter = () => {
  return <Footer />;
};

export default withMarketingSection(MainFooter, {
  selfish: true,
  style: {
    paddingTop: 32,
    backgroundColor: colorsV2.black100
  }
});
